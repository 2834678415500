import React from 'react';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import {Container, Row, Col} from 'react-bootstrap';
import styles from './Product.module.scss';
import arrowViolet from '../../assets/images/arrowViolet.png';
import bg from '../../assets/images/Products/bg.png';

const Product = ({image, subtitle, title, paragraph, link}) => (
    
    <Container fluid className={styles.ContainerProduct}>

        <img src={bg} className={styles.ImgBackground}/>

        {/* img */}
        <Row className={styles.RowImage}>
            <Col md={'auto'} className={styles.ColImage}>
                <Img fluid={image.childImageSharp.fluid}
                     className={styles.Image}
                            // objectFit="cover"
                            // objectPosition="100% 100%"
                 />
                {/* <img alt='image_product' src={image}/> */}
            </Col>
        </Row>

        <Row className={styles.RowInfo}>
            <Col>

                {/* subtitle */}
               {/* <Row>
                    <Col className={styles.ColSubtitle}>
                        <p>{subtitle}</p>
                    </Col>
                </Row>*/}

                {/* title */}
                <Row>
                    <Col className={styles.ColTitle}>
                        <h3>{title}<span>.</span></h3>
                    </Col>
                </Row>
                
                {/* paragraph */}
                <Row className='d-flex justify-content-center'>
                    <Col md={7} className={styles.ColParagraph}>
                        <p>{paragraph}</p>
                    </Col>
                </Row>
                
                {/* link */}
                {/*<Row className='d-flex justify-content-center'>
                    <Col md={'auto'} className={styles.ColLink}>
                        <Link to='/' className={styles.Link}>
                            <img alt="arrow" src={arrowViolet} className={styles.ArrowBefore}/>
                            <p>Conoce más</p>
                            <img alt="arrow" src={arrowViolet} className={styles.ArrowAfter}/>
                        </Link>
                    </Col>
                </Row>*/}

            </Col>
        </Row>

    </Container>

)

export default Product