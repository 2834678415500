import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./LastItem.module.scss";
import arrowViolet from "../../assets/images/arrowViolet.png";

const LastItem = ({ title, tags, image, link }) => (
  <Container fluid className={styles.LastItem}>
    {/* image */}
    <Row className="p-0 m-0">
      <Col className={styles.ColImage}>
        <Link
          className={styles.customcursor}
          to={link}
          style={{ margin: "0px" }}
        >
          <Img
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            fluid={image}
          />
        </Link>
      </Col>
    </Row>

    {/* info */}
    <Row className="m-0 p-0">
      <Col className="p-0">
        {/* subtitle */}
        <Row>
          <Col className={styles.ColSubtitle}>
          {tags.map((tag, key) => {
            if (key === 0) {
              return <a>{tag}</a>
            } else {
              return <a> | {tag}</a>
            }
          })}
          </Col>
        </Row>
        <br></br>
        {/* title */}
        <Row className={styles.RowTitle}>
          <Col xs={4} className={styles.ColTitle}>
            <h3>{title}</h3>
          </Col>
          <Col className={styles.ColLink}>
            <Link to={link} className={styles.Link}>
              <img
                alt="arrow"
                src={arrowViolet}
                className={styles.ArrowBefore}
              />
              <p>Ver caso</p>
              <img
                alt="arrow"
                src={arrowViolet}
                className={styles.ArrowAfter}
              />
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default LastItem;
