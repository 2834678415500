import React from 'react';
import {Container, Row } from 'react-bootstrap';
import styles from './HorizontalText.module.scss';

const HorizontalText = ({text, size = '3rem', top = '0vh', width = '100%'}) => (
    <>
        <Container fluid className={styles.ContainerHorizontalText} style={{top: top, width: width}}>
            <Row className={styles.RowHorizontalText}>
                <h1 style={{fontSize: size}}>{text}</h1>
                <h1 style={{fontSize: size}}>{text}</h1>
            </Row>
        </Container>
    </>
)

export default HorizontalText