import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import LastItem from "../LastItem/LastItem";
import styles from "./Last.module.scss";
import arrowGray from "../../assets/images/arrowGray.png";
import arrowViolet from "../../assets/images/arrowViolet.png";
import { IsMobileDevice } from "../../utils/functions";
import projects from "../../utils/projects";

const Last = ({images}) => {
  const [params, setParams] = useState(null);
  const [displayProjects, setDisplayProject] = useState(false);

  useEffect(() => {
    if (!displayProjects) {
      setDisplayProject(projects(images));
    }
  },[displayProjects,images]);

  useEffect(() => {
    setParams({
      slidesPerView: IsMobileDevice() ? 1.3 : 2.6,
      centeredSlides: false,
      spaceBetween: IsMobileDevice() ? 30 : 20,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }, []);

  return (
    <Container fluid className={styles.ContainerLast} id="TheLast">
      <Row className={styles.RowTitle}>
        <Col md={"auto"} className={styles.ColTitle}>
          <h3>
            Lo último<span>.</span>
          </h3>
        </Col>
        <Col className={styles.ColLink}>
          <Link to="/projects" className={styles.Link}>
            <img alt="arrow" src={arrowGray} className={styles.ArrowBefore} />
            <p>Ver todos</p>
            <img alt="arrow" src={arrowViolet} className={styles.ArrowAfter} />
          </Link>
        </Col>
      </Row>

      <Row className={styles.RowSlider}>
        <Col className="p-0">
          <div className={styles.GradientRight} />
          <div className={styles.GradientLeft} />
          {params && (
            <Swiper {...params}>
              {displayProjects.map((project, key) => {
                return (
                  <div key={key}>
                    <LastItem
                      title={project.title}
                      tags={project.tags}
                      image={images[key]['portada'].childImageSharp.fluid}
                      link={`/project/${project.slug}`}
                    />
                  </div>
                );
              })}
            </Swiper>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Last;
