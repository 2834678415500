import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Product from "../Product/Product";
import styles from "./Products.module.scss";

import { useRecoilValue } from "recoil";
import { ModeSelector } from "../../recoil/selectors";
import { IsMobileDevice } from "../../utils/functions";
import bg from "../../assets/images/Products/bg.png";
import bgLeft from "../../assets/images/Products/left.svg";

// import Swiper core and required components
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// import "swiper/swiper.scss";
// import "swiper/components/pagination/pagination.scss";

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css';
// install Swiper components
/*SwiperCore.use([Pagination]);*/

const Products = ({ images, isBlockchain }) => {
  const mode = useRecoilValue(ModeSelector);
  const [params, setParams] = useState(null);
  // install Swiper components

  useEffect(() => {
    setParams({
      slidesPerView: IsMobileDevice() ? 1.2 : 2.2,
      centeredSlides: IsMobileDevice() ? true : false,
      spaceBetween: 20,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
        clickable: true,
      },
    });
  }, []);

  return (
    <Container fluid className={styles.ContainerLast} id="Products">
      {mode === "dark" ? (
        <img
          alt="background"
          src={bgLeft}
          className={styles.ImgBackground}
          style={{ opacity: ".5" }}
        />
      ) : (
        <img
          alt="background"
          src={bgLeft}
          className={styles.ImgBackground}
          style={{ opacity: ".1" }}
        />
      )}

      <img
        alt="background_mobile"
        src={bg}
        className={styles.ImgBackgroundMobile}
      />

      <Row className={styles.RowTitle}>
        <Col
          md={"2"}
          sm={"auto"}
          className={styles.ColTitle}
          style={{ whiteSpace: isBlockchain && "nowrap" }}
        >
          <h3>
            {isBlockchain ? (
              "Nuestros servicios"
            ) : (
              <>
                {" "}
                Productos <br /> <span className={styles.title}>in house</span>
              </>
            )}
            <span className={styles.point}>.</span>
          </h3>
        </Col>
      </Row>

      <Row className="m-0 p-0">
        <div className={styles.GradientRight} />
        <div className={styles.GradientLeft} />
        <Col className="m-0 p-0">
          {params && (
            <Swiper
                modules={[Pagination]}
              slidesPerView={params.slidesPerView}
              centeredSlides={params.slidesPerView}
              spaceBetween={params.spaceBetween}
              freeMode={params.freeMode}
              pagination={{ clickable: true, type: "progressbar" }}
            >
              <SwiperSlide>
                <Product
                  image={images["producto"] && images["producto"]}
                  subtitle={"marketing"}
                  title={"Mobile E-commerce"}
                  paragraph={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure expedita facilis,"
                  }
                  link={"https://webitesa.web.app/"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Product
                  image={images["producto2"] && images["producto2"]}
                  subtitle={"marketing"}
                  title={"Viral Platform"}
                  paragraph={
                    "GetViral is a disruptive marketing tool that lets you get viral within potential customers"
                  }
                  link={"https://webitesa.web.app/"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Product
                  image={images["producto"] && images["producto"]}
                  subtitle={"marketing"}
                  title={"Mobile E-commerce"}
                  paragraph={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure expedita facilis, obcaecati illum voluptate impedit ad!"
                  }
                  link={"https://webitesa.web.app/"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <Product
                  image={images["producto"] && images["producto"]}
                  subtitle={"marketing"}
                  title={"Mobile E-commerce"}
                  paragraph={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure expedita facilis, obcaecati illum voluptate impedit ad!"
                  }
                  link={"https://webitesa.web.app/"}
                />
              </SwiperSlide>
            </Swiper>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
